import React from 'react'
import styled from 'styled-components'

const StyledTestInfo = styled.div`
`

/**
 * How to add a link to an announcement, here is an example:
 *  {AddLink("1124update.pdf", "Click here for the update")}
 */
const AddLink = (filename: string, text: string) => {
  return <a target="_blank" rel="noopener noreferrer" href={ require(`../assets/extra/${filename}`) }>{ text } </a>
}


export const TestInfo = () => {
  return (
    <StyledTestInfo>
      <b>
       Announcements and stuff you should know
      </b>
       <p>        
        <b>11.20.24
      </b>  
      </p>
      <p>Update to the study guide.  If you joined me on November 14th or before, you
        need this update.  Please download the PDF, print double sided, and replace the
        pages in your study guide.  There are NO major updates, just a few small items
        to clean up.  {AddLink("1124update.pdf", "Click here for the update")} &nbsp;
        Of course contact me if you have any questions.
      </p>
       <p>
         <b>Space reserved for future great stuff
      </b>  
      </p>
      <p>
      <b>
       </b>  
      </p>
      <p>
      </p>
      <p>
      </p>
    </StyledTestInfo>
  )
}
